// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bewindvoering-js": () => import("./../../../src/pages/bewindvoering.js" /* webpackChunkName: "component---src-pages-bewindvoering-js" */),
  "component---src-pages-budgetbeheer-js": () => import("./../../../src/pages/budgetbeheer.js" /* webpackChunkName: "component---src-pages-budgetbeheer-js" */),
  "component---src-pages-curatele-js": () => import("./../../../src/pages/curatele.js" /* webpackChunkName: "component---src-pages-curatele-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentorschap-js": () => import("./../../../src/pages/mentorschap.js" /* webpackChunkName: "component---src-pages-mentorschap-js" */),
  "component---src-pages-tarieven-js": () => import("./../../../src/pages/tarieven.js" /* webpackChunkName: "component---src-pages-tarieven-js" */)
}

